<template>
  <component :is="tagName" class="teaser-list-item">
    <div class="teaser-list-item__date-container">
      <date-box
        :date="new Date(anEvent.beginning)"
        class="teaser-list-item__date-box"
      />
      <div class="teaser-list-item-description">
        <div class="teaser-list-item-description__subject-container">
          <h4 class="teaser-list-item-description__subject">
            {{ anEvent.subject }}
          </h4>
          <div class="teaser-list-item-description__time">
            {{ formattedTime }}
          </div>
        </div>
        <div v-if="anEvent.icons" class="teaser-list-item-description__icons">
          <el-tooltip
            v-for="(icon, index) in anEvent.icons"
            :key="'icon' + index"
            :content="icon.tooltip"
          >
            <span :class="icon.iconClass" />
          </el-tooltip>
        </div>
      </div>
    </div>
    <slot></slot>
  </component>
</template>

<script>
import { fromTo } from '../../../shared/date/format'
import DateBox from '../../atoms/DateBox/DateBox.vue'
export default {
  name: 'TeaserListItem',
  props: {
    anEvent: {
      type: Object
    },
    tagName: {
      type: String,
      default: 'li'
    }
  },
  computed: {
    formattedTime() {
      return fromTo(
        new Date(this.anEvent.beginning),
        new Date(this.anEvent.end)
      )
    }
  },
  components: { DateBox }
}
</script>
<style lang="scss">
@import '_assets/TeaserListItem';
</style>
