<template>
  <el-form-item class="textarea" :label="label">
    <el-input
      :value="value"
      @input="val => $emit('input', val)"
      type="textarea"
      name="name"
    />
  </el-form-item>
</template>
<script>
export default {
  name: 'TextAreaItem',
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    name: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.textarea {
  height: 5rem;

  ::v-deep .el-textarea,
  ::v-deep textarea {
    height: 100%;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-form-item__content {
    $border-radius: rem(25px);
    overflow: hidden;
    border-radius: $border-radius;
  }
}
</style>
