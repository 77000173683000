<template>
  <div
    :class="[
      'e-counseling-event-status',
      `e-counseling-event-status--${status}`
    ]"
    :label="text"
  >
    <span :class="[icon, 'e-counseling-event-status__icon', 'mr-1']" />
    <span class="e-counseling-event-status__text" v-text="text" />
  </div>
</template>

<script>
export default {
  name: 'CounselingEventStatus',
  props: {
    status: {
      type: String
    }
  },
  computed: {
    text() {
      return this.$t('counselingEvent.' + this.status)
    },
    icon() {
      switch (this.status) {
        case 'booked':
          return 'el-icon-check'
        case 'cancelled':
          return 'el-icon-close'
      }
      return ''
    }
  }
}
</script>
<style lang="scss">
@import '_assets/CounselingEventStatus';
</style>
