export const fromTo = (start, end, showDay = true) => {
  const [formattedStartDay, formattedEndDay] = [start, end].map(
    formatDayNumeric
  )
  const [formattedStartTime, formattedEndTime] = [start, end].map(
    formatShortTimeNumeric
  )
  return `${showDay ? (formattedStartDay + ' ') : ''}${formattedStartTime} - ${
    formattedStartDay === formattedEndDay ? '' : formattedEndDay + ' '
  }${formattedEndTime}`
}

export const formatDayNumeric = aDate => {
  const dayFormatter = new Intl.DateTimeFormat(undefined, { weekday: 'short' })
  return dayFormatter.format(aDate)
}

export const formatShortTimeNumeric = aDate => {
  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit'
  })
  return timeFormatter.format(aDate)
}
