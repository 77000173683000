<template>
  <section v-loading="pageDataLoading">
    <CounselingEvents
      class="e-counseling-events-overview"
      :items="mappedCounselingEvents"
      @book="handleClickBook"
      @cancel="handleClickCancel"
      @save="save"
      @edit="handleEdit"
    />
    <confirm-booking-dialog
      :key="'confirm' + (selectedEvent ? selectedEvent.id : '')"
      :open.sync="confirmDialogOpen"
      :counseling-event="selectedEvent"
      @submit="handleBookEvent"
    />
    <cancel-booking-dialog
      :key="'cancel' + (selectedEvent ? selectedEvent.id : '')"
      :open.sync="cancelDialogOpen"
      :counseling-event="selectedEvent"
      @submit="handleCancelEvent"
    />
  </section>
</template>

<script>
import CounselingEvents from 'organisms/CounselingEvents/CounselingEvents'
import ConfirmBookingDialog from 'organisms/CounselingEvents/ConfirmBookingDialog'
import { mapActions, mapGetters } from 'vuex'
import CancelBookingDialog from '../../organisms/CounselingEvents/CancelBookingDialog.vue'

export default {
  name: 'CounselingEventsOverview',
  components: {
    CounselingEvents,
    ConfirmBookingDialog,
    CancelBookingDialog
  },
  computed: {
    ...mapGetters(['counselingEvents', 'pageDataLoading']),
    activeId() {
      return this.$route.params.id ?? null
    },
    activeEvent() {
      return this.counselingEvents.find(el => el.id === this.activeId) ?? null
    },
    mappedCounselingEvents() {
      if (!this.activeEvent) {
        return this.counselingEvents
      }
      return this.counselingEvents.map(el => ({
        ...el,
        active: el.id === this.activeEvent.id
      }))
    },
    bookedEvent() {
      return this.mappedCounselingEvents
        .filter(el => el.status === 'booked')
        .pop()
    }
  },
  async created() {
    await this.getCounselingEvents()
    if (this.activeId && !this.activeEvent) {
      this.$message.error(this.$t('counselingEvent.notFound'))
    }
    if (!this.activeId && this.bookedEvent) {
      this.$router.replace({
        name: this.$route.name,
        params: { id: this.bookedEvent.id }
      })
    }
  },
  data() {
    return {
      confirmDialogOpen: false,
      cancelDialogOpen: false,
      selectedEvent: null
    }
  },
  watch: {
    confirmDialogOpen: function(newVal) {
      if (newVal) {
        return
      }
      this.selectedEvent = null
    },
    cancelDialogOpen: function(newVal) {
      if (newVal) {
        return
      }
      this.selectedEvent = null
    }
  },
  methods: {
    ...mapActions([
      'getCounselingEvents',
      'bookCounselingEvent',
      'updateKidBooking',
      'cancelCounselingEvent',
      'editCounselingEvent'
    ]),
    handleClickBook(event) {
      this.confirmDialogOpen = true
      this.selectedEvent = event
    },
    handleClickCancel(event) {
      this.cancelDialogOpen = true
      this.selectedEvent = event
    },
    handleBookEvent(data) {
      this.bookCounselingEvent(data)
        .then(() => {
          this.getCounselingEvents()
          this.$router.push({
            name: 'counselingEventsOverview',
            params: { id: data.id }
          })
          this.confirmDialogOpen = false
          this.$message.success(this.$t('counselingEvent.updateSuccess'))
        })
        .catch(error => {
          if (error.messages && error.messages[0]) {
            this.$message.error(this.$t(error.messages[0]))
            return
          }
          console.error(error)
        })
    },
    handleCancelEvent(data) {
      this.cancelCounselingEvent(data).then(() => {
        this.getCounselingEvents()
        this.$router.push({
          name: 'counselingEventsOverview',
          params: { id: data.id }
        })
        this.cancelDialogOpen = false
        this.$message.success(this.$t('counselingEvent.cancelSuccess'))
      })
    },
    async save(data) {
      await this.updateKidBooking(data)
      this.$message.success(this.$t('counselingEvent.updateSuccess'))
    },
    handleEdit(partialEvent) {
      this.editCounselingEvent(partialEvent)
    }
  }
}
</script>
