<template>
  <time class="e-date-box" :datetime="dateString">
    <div class="e-date-box__day">{{ zeroPaddedDay }}</div>
    <div class="e-date-box__month">{{ formattedMonth }}</div>
  </time>
</template>

<script>
export default {
  name: 'DateBox',
  props: {
    date: {
      type: Date
    }
  },
  computed: {
    dateString() {
      if (!this.date) {
        return ''
      }
      return this.date.toLocaleDateString()
    },
    zeroPaddedDay() {
      if (!this.date) {
        return ''
      }
      const day = this.date.getDate()
      return day < 10 ? '0' + day : day
    },
    formattedMonth() {
      if (!this.date) {
        return ''
      }
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'short'
      }).format(this.date)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/DateBox';
</style>
