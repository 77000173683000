<template>
  <el-button type="ghost" class="e-cancel-button" @click="$emit('click')">
    <IconClose class="icon" />
    <span class="ml-1">{{ text ? text : $t('system.cancel') }}</span>
  </el-button>
</template>
<script>
import IconClose from 'assets/icons/close.svg'
export default {
  name: 'CancelButton',
  components: {
    IconClose
  },
  props: {
    text: {
      type: String
    }
  }
}
</script>
<style lang="scss">
@import '_assets/CancelButton';
</style>
