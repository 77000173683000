<template>
  <div class="e-counseling-events">
    <template v-if="!(items && items.length)">
      <div class="e-counseling-events__no-items">
        {{ $t('counselingEvent.noItems') }}
      </div>
    </template>
    <template v-else>
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(item) in items"
          :key="item.id"
          :name="item.id"
          class="e-counseling-event"
          :class="{ 'e-counseling-event--active': item.active }"
        >
          <template slot="title">
            <teaser-list-item :an-event="toListItem(item)" tag-name="div">
              <div class="align-self-center ml-auto">
                <template v-if="item.status === 'published'">
                <el-tooltip :disabled="isBookable(item)" :content="$t('counselingEvent.conflictingEvent')">
                  <el-button
                    type="primary"
                    class="e-btn e-btn--primary"
                    :class="{ 'is-disabled': !isBookable(item) }"
                    @click="$emit('book', item)"
                    >{{ $t('counselingEvent.book') }}</el-button
                  >
                  </el-tooltip>
                </template>
                <counseling-event-status v-else :status="item.status" />
              </div>
            </teaser-list-item>
          </template>
          <div class="e-counseling-event__body">
            <div class="e-counseling-event__body-descriptions">
              <div v-if="hasText($sanitize(item.bookedDescription))">
                <div>
                  <strong>
                    {{ $t('counselingEvent.descriptionBooking') }}
                  </strong>
                </div>
                <i v-html="$sanitize(descriptionWithLinks(item.bookedDescription))" />
              </div>
              <div v-if="hasText(item.description)">
                <div><strong>{{ $t('counselingEvent.descriptionEvent') }}</strong></div>
                <div v-html="$sanitize(descriptionWithLinks(item.description))" />
              </div>
            </div>
            <div class="e-counseling-event__body-actions">
              <template v-if="item.status === 'booked'">
                <el-button
                  type="alert"
                  @click="$emit('cancel', item)"
                  >{{ $t('counselingEvent.cancel') }}</el-button
                >
              </template>
              <router-link
                v-if="item.status === 'booked' && item.channel === 'chat'"
                class="e-btn e-btn--success"
                :to="{ name: 'counselingEventChat', params: { id: item.id } }"
              >
                {{ $t('counselingEvent.chat.linkTitle') }}
              </router-link>
            </div>

          </div>
          <template v-if="item.status === 'booked'">
            <el-form
              class="e-form change-event-form"
              @submit.native="() => submitBookedChange(item)"
            >
              <el-form-item :label="$t('counselingEvent.channel.name')">
                <el-select
                  :value="item.channel"
                  @change="(val) => $emit('edit', {id: item.id, channel: val})"
                  class="el-select--fullwidth"
                  name="channel"
                >
                  <el-option
                    v-for="channel in item.channels"
                    :key="'channel-' + channel"
                    :label="$t(`counselingEvent.channel.${channel}`)"
                    :value="channel"
                  />
                </el-select>
              </el-form-item>
              <textarea-item
                :value="item.remark"
                @input="(val) => $emit('edit', {id: item.id, remark: val})"
                :label="$t('counselingEvent.remark')"
                name="remark"
              />
              <el-button
                type="primary"
                @click="() => submitBookedChange(item)"
                >{{ $t('system.save') }}</el-button
              >
            </el-form>
          </template>
        </el-collapse-item>
      </el-collapse>
    </template>
  </div>
</template>

<script>
import TeaserListItem from '../../molecules/TeaserList/TeaserListItem.vue'
import CounselingEventStatus from './CounselingEventStatus.vue'
import TextareaItem from '../../atoms/TextareaItem/TextareaItem.vue'
import { toListItem } from '../../../shared/util/counselingEvent'
export default {
  name: 'CounselingEvents',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      activeNames: [],
      channel: '',
      remark: ''
    }
  },
  created() {
    this.setActiveNames(this.items)
  },
  watch: {
    items: function(newVal) {
      this.setActiveNames(newVal)
    }
  },
  methods: {
    descriptionWithLinks: function(description) {
      const descriptionWithLink = description.replace(/((https|http)?:\/\/[^\s]+)/g, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })
      return this.convertLineBreaks(descriptionWithLink);
    },
    convertLineBreaks(text) {
      return text.replace(/\n/g, "<br/>")
    },
    setActiveNames(items) {
      const newActive = items.filter(el => el.active).map(el => el.id)
      let newAdded = false
      newActive.forEach(el => {
        if (!this.activeNames.includes(el)) {
          this.activeNames.push(el)
        }
      })
      if (!newAdded) {
        return
      }
      this.activeNames = newActive
    },
    submitBookedChange(counselingEvent) {
      this.$emit('save', {
        id: counselingEvent.id,
        channel: counselingEvent.channel,
        remark: counselingEvent.remark
      })
    },
    isBookable(item) {
      const start = new Date(item.beginning).getTime()
      const end = new Date(item.end).getTime()
      for (const currentItem of this.items) {
        if (currentItem.status !== 'booked') {
          continue
        }
        const currentItemStart = new Date(currentItem.beginning).getTime()
        const currentItemEnd = new Date(currentItem.end).getTime()
        if (
          (start <= currentItemStart && end >= currentItemStart) ||
          (start <= currentItemEnd && end >= currentItemEnd)
        ) {
          return false
        }
      }
      return true
    },
    toListItem(counselingEvent) {
      return toListItem(counselingEvent, this)
    },
    hasText(html) {
      if (!html) {
        return false
      }
      const div = document.createElement('div')
      div.innerHTML = html
      return !!div.innerText
    }
  },
  components: { TeaserListItem, CounselingEventStatus, TextareaItem }
}
</script>

<style lang="scss">

.e-counseling-events {
  .el-collapse {
    border: none;
  }
  .e-counseling-event {
    margin-bottom: 1.5rem;
    $box-shadow: 0px 0px 5px #0000004d;

    &:last-of-type {
      margin-bottom: 0;
    }
    .el-collapse-item__wrap {
      border-bottom: none;
      box-shadow: $box-shadow;
    }

    .el-collapse-item__arrow {
      font-size: 1rem;
      @include break(m) {
        font-size: 2rem;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1.5rem;
      &-actions, &-descriptions {
        display:flex;
        flex-direction:column;
      }
      &-actions {
        gap: 0.5rem;
        align-items: center;
      }
      &-descriptions {
        gap: 0.75rem;
      }
    }

    .el-collapse-item__content {
      padding: 2ch;
    }

    &__title {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      align-items: center;
      width: 100%;
      padding: 1rem 5ch 1rem 0;
    }

    .el-collapse-item__header {
      height: auto;
      line-height: 1.5;
      box-shadow: $box-shadow;

      .teaser-list-item {
        box-shadow: none;
        margin-right: 2ch;

        @include break(m) {
          margin-right: 2rem;
        }
      }
    }
    .el-collapse-item__title {
      margin: 0;
    }

    @include break(sm) {
      &__title {
        flex-wrap: nowrap;
      }
      .el-collapse-item__title {
        margin: 0 0 0 2ch;
      }
    }

    .change-event-form {
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }
}
</style>
