<template>
  <el-dialog
    v-if="open"
    :title="$t('counselingEvent.cancelBooking')"
    :visible="open"
    @update:visible="
      val => {
        $emit('update:open', val)
      }
    "
    :modal-append-to-body="false"
    custom-class="el-dialog--small e-cancel-booking-dialog"
  >
    <el-form
      id="cancel-booking-form"
      class="e-form mt-2"
      @submit.native="submit"
    >
      <textarea-item
        v-model="reason"
        :label="$t('counselingEvent.cancelReasonLabel')"
        name="reason"
        @keyup.native.ctrl.enter="submit"
      />
    </el-form>
    <div slot="footer" class="dialog-footer">
      <cancel-button class="e-align--left" @click="closeDialog" />

      <el-button type="alert" @click="submit" form="cancel-booking-form">{{
        $t('counselingEvent.cancel')
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CancelButton from '../../atoms/CancelButton/CancelButton.vue'
import TextareaItem from '../../atoms/TextareaItem/TextareaItem.vue'
export default {
  name: 'CancelBookingDialog',
  components: { CancelButton, TextareaItem },
  props: {
    counselingEvent: {
      type: Object
    },
    open: {
      type: Boolean
    }
  },
  data() {
    return {
      reason: ''
    }
  },
  watch: {
    open(newVal) {
      if (
        newVal &&
        this.counselingEvent &&
        this.counselingEvent.channels.length === 1
      ) {
        this.channel = this.counselingEvent.channels[0]
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:open', false)
    },
    submit() {
      this.$emit('submit', { id: this.counselingEvent.id, reason: this.reason })
    }
  }
}
</script>
