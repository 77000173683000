const iconClassMap = {
  telephone: 'mdi mdi-phone-in-talk-outline',
  chat: 'mdi mdi-forum-outline',
  external_chat: 'mdi mdi-forum',
  video_chat: 'mdi mdi-video-outline',
  on_site: 'mdi mdi-home-city-outline'
}

export const toListItem = (counselingEvent, vm) => {
  return {
    id: counselingEvent.id,
    beginning: counselingEvent.beginning,
    end: counselingEvent.end,
    icons: counselingEvent.channels.map(channel => ({
      iconClass: iconClassMap[channel],
      tooltip: vm.$t('counselingEvent.channel.' + channel)
    })),
    subject: counselingEvent.subject,
    status: counselingEvent.status
  }
}
